import React from "react";
import PropTypes from "prop-types";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "./PublicRoute";
import Main from "pages/Homepage/Main";
import "locales/i18n";
import { useGetPostSettingData, useGetSettingData } from "hooks";
import About from "pages/About.js/About";
import ContactUs from "pages/Contact/ContactUs";
import FaqPage from "pages/FaqPage/FaqPage";
import { PageNotFound } from "common/components/PageNotFound";
import LoginPage from "pages/Auth/LoginPage";
import Registration from "pages/Auth/Registration";
import OtpVarification from "pages/Auth/OtpVarification";
import CmsRoutes from "pages/CmsPage/CmsRoutes";
import ForgotPasswordPage from "pages/Auth/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "pages/Auth/ResetPassword/ResetPasswordPage";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "pages/Dashboard/Dashboard";
import EditProfilePage from "pages/Account/EditProfile/EditProfilePage";
import ChangePassword from "pages/Account/ChangePassword/ChangePassword";
import DeactivateAccount from "pages/Account/DeactivateAccount/DeactivateAccount";
import ManageCard from "pages/Payment/ManageCard";
import BecomeBusinessUserPage from "pages/BecomeBusinessUser/BecomeBusinessUserPage";
import AdsRoutes from "pages/Ads/AdsRoutes";
import EditBecomeUserPage from "pages/BecomeBusinessUser/EditBecomeUserPage";
import PostListRoute from "pages/PostAds/PostListRoute";
import ViewAds from "pages/ViewAds/ViewAds";
import UserView from "pages/UserView/UserView";
import UserProfileView from "pages/UserView/UserProfileView";
import PaymentHistory from "pages/Payment/PaymentHistory";
import Notifications from "pages/Notification/Notifications";
import UserChat from "pages/Chats/UserChat";
import { io } from "socket.io-client";
import {
  HOME,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  OTP_VERIFICATION,
  ABOUT,
  CONTACT,
  FAQ,
  POST_ADVERTISEMENT,
  USER_PROFILE_VIEW,
  CMS_ROUTES,
  DASHBOARD,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  DEACTIVATE_ACCOUNT,
  MANAGE_CARD,
  BECOME_BUSINESS_USER,
  EDIT_BECOME_BUSINESS_USER,
  PAYMENT_HISTORY,
  ADS,
  NOTIFICATIONS,
  CHAT,
} from "config";
import PostAdsList from "pages/PostAds/PostAdsList";
const PagesRoutes = ({ t }) => {
  const socketConnection = io(process.env.REACT_APP_SOCKET_URL);
  const location = useLocation();

  const route_name =
    location.pathname === "/" ||
    location.pathname.startsWith("/page/") ||
    location.pathname.startsWith("/company")
      ? "home"
      : location.pathname.substr(1);

  const { data: getSettingData } = useGetSettingData(
    route_name,
    () => {},
    (error) => {
      if (error?.data?.redirect == true) {
        console.error("Error occurred: ", error.message);
      }
    }
  );
  const { data: getSeoData } = useGetPostSettingData(
    "general_post",
    () => {},
    (error) => {
      if (error?.data?.redirect == true) {
        console.error("Error occurred: ", error.message);
      }
    }
  );

  return (
    <>
      <Routes>
        <Route element={<PublicRoute getSettingData={getSettingData} t={t} />}>
          <Route
            exact
            path={LOGIN}
            element={<LoginPage getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={REGISTER}
            element={<Registration getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={FORGOT_PASSWORD}
            element={
              <ForgotPasswordPage getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            exact
            path={RESET_PASSWORD}
            element={
              <ResetPasswordPage getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            exact
            path={OTP_VERIFICATION}
            element={<OtpVarification getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={HOME}
            element={<Main getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={ABOUT}
            element={<About getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={CONTACT}
            element={<ContactUs getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={FAQ}
            element={<FaqPage getSettingData={getSettingData} t={t} />}
          />
          {/* shrey added routes */}
          <Route
            exact
            path="/search/:state?/:city?/*"
            element={
              <PostAdsList
                getSettingData={getSettingData}
                t={t}
                getSeoData={getSeoData}
              />
            }
          />
          <Route
            exact
            path="/abcd"
            element={<ViewAds getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/:advertisement_info"
            element={
              <UserView
                getSettingData={getSettingData}
                t={t}
                socket={socketConnection}
                getSeoData={getSeoData}
              />
            }
          />
          <Route
            exact
            path={USER_PROFILE_VIEW + "/:user_id"}
            element={
              <UserProfileView
                getSettingData={getSettingData}
                t={t}
                socket={socketConnection}
              />
            }
          />

          <Route
            path={CMS_ROUTES + "/*"}
            element={<CmsRoutes getSettingData={getSettingData} t={t} />}
          />
        </Route>
        <Route
          element={
            <PrivateRoute
              getSettingData={getSettingData}
              t={t}
              socket={socketConnection}
            />
          }
        >
          <Route
            path={DASHBOARD}
            element={<Dashboard getSettingData={getSettingData} t={t} />}
          />
          <Route
            path={EDIT_PROFILE}
            element={<EditProfilePage getSettingData={getSettingData} t={t} />}
          />
          <Route
            path={CHANGE_PASSWORD}
            element={<ChangePassword getSettingData={getSettingData} t={t} />}
          />
          <Route
            path={DEACTIVATE_ACCOUNT}
            element={
              <DeactivateAccount getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            path={MANAGE_CARD}
            element={<ManageCard getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={BECOME_BUSINESS_USER}
            element={
              <BecomeBusinessUserPage getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            exact
            path={EDIT_BECOME_BUSINESS_USER}
            element={
              <EditBecomeUserPage getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            exact
            path={PAYMENT_HISTORY}
            element={<PaymentHistory getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={ADS + "/*"}
            element={
              <AdsRoutes
                getSettingData={getSettingData}
                t={t}
                socket={socketConnection}
              />
            }
          />
          <Route
            exact
            path={NOTIFICATIONS}
            element={<Notifications getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path={CHAT}
            element={
              <UserChat
                getSettingData={getSettingData}
                t={t}
                socket={socketConnection}
              />
            }
          />
        </Route>

        <Route
          path="*"
          element={<PageNotFound getSettingData={getSettingData} t={t} />}
        />
      </Routes>
    </>
  );
};

PagesRoutes.propTypes = {
  t: PropTypes.func,
};

export default PagesRoutes;
