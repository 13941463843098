import client from "libs/HttpClient";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { storeToken } from "store";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// Function to request permission and get FCM token
export const requestForToken = async (dispatch) => {
  if ("Notification" in window) {
    const hasAlertedUser = localStorage.getItem("hasAlertedUser");
    const permission = await Notification.requestPermission();

    if (permission === "denied" && !hasAlertedUser) {
      // alert("Please allow notifications");
      localStorage.setItem("hasAlertedUser", "true");
    } else if (permission === "granted") {
      generateToken(dispatch);
    }
  }
};

// Function to generate and store the token
const generateToken = async (dispatch) => {
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY,
    });
    if (token) {
      dispatch(storeToken(token)); // Dispatch the action with the generated token
      await client.post("/notifications/store", { token });
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (error) {
    console.error("Error generating FCM token:", error);
  }
};

// Listen for incoming messages
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      if (payload.notification) {
        const { title, body } = payload.notification;
        new Notification(title, {
          body: body,
          // icon: "/path-to-icon.png", // Optional: set a notification icon
        });
      }
      resolve(payload);
    });
  });

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
