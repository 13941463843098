import React from "react";

function BusinessRole() {
  return (
    <>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 14.1663C20.1297 14.1663 22.6667 11.6293 22.6667 8.49967C22.6667 5.37006 20.1297 2.83301 17 2.83301C13.8704 2.83301 11.3334 5.37006 11.3334 8.49967C11.3334 11.6293 13.8704 14.1663 17 14.1663Z"
          fill="white"
          stroke="white"
          strokeWidth="2.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.75 31.166C29.75 24.1245 24.0415 18.416 17 18.416C9.95846 18.416 4.25 24.1245 4.25 31.166"
          stroke="white"
          strokeWidth="2.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 31.166L19.8333 27.6243L17 18.416L14.1666 27.6243L17 31.166Z"
          fill="white"
          stroke="white"
          strokeWidth="2.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}

export default BusinessRole;
