import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TNTable } from "common/components/TNTable";
import { paymentStatusOptions } from "config";
import {
  getLocalStorageData,
  setFormatDateAndTimeWithSeparator,
} from "helpers";
import { useGetPaymentHistory } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/page/_alert_confirm.scss";
import moment from "moment";
import "assets/scss/page/_ads.scss";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "config";
import { CommonHelmet } from "common";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "50px",
    // padding: '10px 18px',
    color: "#ffff", // Replace with your $dark-purple value
    border: "1px solid #4d4d4d", // Replace with your $grey value
    borderRadius: "10px",
    fontSize: "16px",
    backgroundColor: "transparent",
    fontWeight: "300",
    boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 25%)",
    "&:hover": {
      borderColor: "#4A4A4A", // Adjust color on hover if needed
    },
  }),
  singleValue: (provided, state) => {
    const color = state.data.isSelected ? "#ffff" : "#ffff"; // Change color when selected
    return {
      ...provided,
      color: color,
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: "#ffffff",
    };
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#0a5640" : "transparent",
    color: state.isSelected ? "black" : "black",
    // color: 'black',
    "&:hover": {
      backgroundColor: "#0a5640",
      color: "black",
    },
  }),
};
const PaymentHistory = ({ t }) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [paginationData, setPaginationData] = useState(null);

  const defaultValue = {
    currentPage: 1,
    searchText: "",
    searchTextFilter: "",
    status: "",
    fromDate: "",
    toDate: "",
  };
  const storeData = getLocalStorageData("adminUsersTable", defaultValue);
  const [searchText, setSearchText] = useState(storeData.searchText);
  const [searchTextFilter, setSearchTextFilter] = useState(
    storeData.searchTextFilter
  );
  const [status, setStatus] = useState(storeData.status);
  const [fromDate, setFromDate] = useState(storeData.fromDate);
  const [toDate, setToDate] = useState(storeData.toDate);
  useEffect(() => {
    let adminUsersTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      currentPage: currentPage,
      status: status,
      fromDate: fromDate,
      toDate: toDate,
    };
    localStorage.adminUsersTable = JSON.stringify(adminUsersTable);
  }, [currentPage, searchText, searchTextFilter, status, toDate, fromDate]);

  const fromDateFormatted = fromDate
    ? moment(fromDate).format("YYYY-MM-DD")
    : "";

  const toDateFormatted = toDate ? moment(toDate).format("YYYY-MM-DD") : "";

  const { refetch } = useGetPaymentHistory(
    {
      current_page: currentPage,
      search_text_filter: searchTextFilter,
      status: status,
      from_date: fromDateFormatted,
      to_date: toDateFormatted,
    },
    (res) => {
      setDatas(res?.data?.payment_history);
      setPaginationData(res?.data?.pagination);
    }
  );

  const columnsJson = [
    {
      Header: (
        <span
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
            whiteSpace: "normal",
          }}
          dangerouslySetInnerHTML={{
            __html: t("front_page.payment_history_created_at"),
          }}
        ></span>
      ),
      accessor: "created_at",
      disableSortBy: false,
      Cell: ({ value }) => {
        return (
          <>
            <span>
              {setFormatDateAndTimeWithSeparator(value).split("|")[0]}
            </span>
            <br />
            <span>
              {setFormatDateAndTimeWithSeparator(value).split("|")[1]}
            </span>
          </>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("front_page.payment_history_transaction_id_label"),
          }}
        ></span>
      ),
      accessor: "transaction_id",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <span
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "normal",
              maxWidth: "100%",
              display: "block",
            }}
          >
            {value || "-"}
          </span>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("front_page.payment_history_ads_name"),
          }}
        ></span>
      ),
      accessor: "advertisement_name",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <>
            <span
              style={{
                wordWrap: "break-word",
                wordBreak: "break-all",
                whiteSpace: "normal",
              }}
            >
              {value != "" && value != "null" ? value : "-"}
            </span>
            <br />
          </>
        );
      },
    },
    {
      Header: (
        <span
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          {t("front_page.payment_history_category")}
        </span>
      ),
      accessor: "subcategory_names",
      disableSortBy: false,
      Cell: ({ row }) => {
        return (
          <>
            <span
              style={{
                maxWidth: "150px",
                display: "inline-block",
                textWrap: "auto",
              }}
            >
              {row.original.parent_category_name}{" "}
              {row.original.parent_category_name && "| "}
              <br />
              {row.original.subcategory_names}{" "}
            </span>
            <br />
          </>
        );
      },
    },
    {
      Header: (
        <span
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
          dangerouslySetInnerHTML={{
            __html: t("front_page.payment_history_validity"),
          }}
        ></span>
      ),
      accessor: "validity_in_days",
      disableSortBy: true,
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("front_page.payment_history_price"),
          }}
        ></span>
      ),
      accessor: "advertisement_price",
      disableSortBy: true,
    },
    {
      Header: (
        <span
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
          dangerouslySetInnerHTML={{
            __html: t("front_page.payment_history_start_end_date"),
          }}
        ></span>
      ),
      accessor: "start_date",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <span>
              {row.original.start_date}
              <br />
              {" to"}
              <br />
              {row.original.end_date}
            </span>
            <br />
          </>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("front_page.payment_history_status"),
          }}
        ></span>
      ),
      accessor: "payment_status",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <>
            {value == 1 ? (
              <span className="me-2 ads-table-actions text-decoration-none status-btn  bg-success">
                {"Success"}
              </span>
            ) : (
              <span className="me-2 ads-table-actions text-decoration-none status-btn  bg-danger">
                {"Failed"}
              </span>
            )}

            <br />
          </>
        );
      },
    },
  ];

  const columns = useMemo(() => columnsJson, []);
  if (paginationData === null) {
    return null;
  }

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleSearch = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text?.target?.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter("");
    }
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    setSearchTextFilter("");
    setSearchText("");
    setFromDate("");
    setToDate("");
    setStatus(null);
  };

  const options = paymentStatusOptions(t);
  return (
    <>
      <CommonHelmet
        title={"front_page.payment_history_seo_title"}
        description={"front_page.payment_history_seo_title"}
      />
      <Row>
        <div className="d-flex business-heading">
          <div>
            <Button onClick={() => navigate(DASHBOARD)}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </div>
          <div>
            <h3 className="ms-2">{t("front_page.payment_history_title")}</h3>
          </div>
        </div>
      </Row>
      <Row className="pt-4">
        <div className="d-flex gap-2 justify-content-end gap-3 my-3 mt-0 flex-wrap align-items-center filter-row filter-row-1">
          <Form.Group
            controlId="search"
            className="filter-field-space filter-status position-relative search-group d-flex align-items-center"
          >
            <Form.Control
              placeholder="Search"
              className="filter-column search-box form-field payment-filter"
              onKeyUp={handleSearch}
              onChange={handleSearch}
              value={searchText}
            />
            <span className="search-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z"
                  fill="white"
                />
              </svg>
            </span>
          </Form.Group>
          <Form.Group className="filter-field-space filter-status">
            <Select
              className="filter-column custom-filter-input payment-input"
              options={options}
              styles={customStyles}
              value={options?.filter((option) => option.value == status)}
              onChange={(selectedOption) => {
                setCurrentPage(1);
                setStatus(selectedOption.value);
              }}
            />
          </Form.Group>
          <Row className="my-3">
            <div className="d-flex gap-2 justify-content-end gap-3 align-items-center flex-wrap row-gap-lg-0 filter-row">
              <InputGroup className="custom-date-picker payment-date-picker">
                <InputGroup.Text>
                  {t("front_page.list_ads_from")}
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  className="filter-column form-field bg-transparent text-light"
                  aria-label="Select date"
                  value={fromDate} // Bind the state to the input value
                  onChange={(e) => {
                    setFromDate(e.target.value); // Update state with selected date
                    setCurrentPage(1);
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                  max={
                    toDate !== "" && toDate !== undefined ? toDate : new Date()
                  } // Set max date
                />
              </InputGroup>

              <InputGroup className="custom-date-picker payment-date-picker">
                <InputGroup.Text>{t("front_page.list_ads_to")}</InputGroup.Text>
                <Form.Control
                  type="date"
                  className="filter-column form-field bg-transparent text-light"
                  aria-label="Select date"
                  value={toDate} // Bind the state to the input value
                  onChange={(e) => {
                    setToDate(e.target.value); // Update state with selected date
                    setCurrentPage(1);
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </InputGroup>
              {/* </div> */}
            </div>
          </Row>
          <Button onClick={handleClearFilter} className="cancel-btn px-4">
            {t("front_page.list_ads_cancel")}
          </Button>
        </div>
      </Row>
      <div
        className="ads_pagination payment-table mt-4 table-wrapper row"
        style={{ overflowX: "auto", width: "auto" }}
      >
        <TNTable
          columns={columns}
          data={datas}
          paginationData={paginationData}
          onSelectPage={pageIndexHandle}
          t={t}
          pageIndexGet={currentPage - 1}
          key={Math?.floor(Math.random() * (1000 - 1 + 1) + 1)}
          style={{ tableLayout: "fixed", minWidth: "1000px" }} // Adjust as necessary
        />
      </div>
    </>
  );
};

export default PaymentHistory;
