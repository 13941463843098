import client from "../libs/HttpClient";
class AdsService {
  static listAds({ params }) {
    return client.get("ads/list", { params });
  }
  static addAds(request) {
    return client.post("ads/add", request, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }
  static getVariations({ params }) {
    return client.get("ads/get-variations", { params });
  }
  static getAdvertisement({ params }) {
    return client.get("ads/get-advertisement", { params });
  }
  static getSates(request) {
    return client.get("ads/get-states", request);
  }
  static getCities(request) {
    return client.get("ads/get-cities", request);
  }
  static statusChange(request) {
    return client.put("ads/status-change", request);
  }
  static adsStatusChange(request) {
    return client.put("ads/ads-status-change", request);
  }
  static updateRenewStatus(request) {
    return client.put("ads/update-renew-status", request);
  }
  static getAdvertisementByID({ params }) {
    return client.get("ads/get-advertisement-by-id", { params });
  }
  static getPricing({ params }) {
    return client.get("ads/get-pricing", { params });
  }
  static getFinalPrice({ params }) {
    return client.get("ads/get-final-price", { params });
  }
  static getReviews({ params }) {
    return client.get("ads/get-reviews", { params });
  }
  static addReviews(request) {
    return client.post("ads/add-reviews", request);
  }
  static getUserPostCount({ params }) {
    return client.get("ads/get-user-posts-counts", { params });
  }
  static getRecommendedAds({ params }) {
    return client.get("ads/get-recommended-ads", { params });
  }
  static getLastAds(request) {
    return client.post("ads/get-last-ads", request);
  }
  static clearDraft(request) {
    return client.post("ads/clear-draft", request);
  }
  static storeRating(request) {
    return client.post("rating/store-rating", request);
  }
  static storeReport(request) {
    return client.post("report/store-report", request);
  }

  static getUserPostList({ params }) {
    return client.get("ads/get-user-posts-list", { params });
  }

  static getRatingList({ params }) {
    return client.get("rating/get-rating", { params });
  }
  static addFavorite(request) {
    return client.post("ads/add-favorite", request);
  }
  static getFavorite({ params }) {
    return client.get("ads/list-favorite", { params });
  }
  static getCompleted({ params }) {
    return client.get("ads/list-completed", { params });
  }
  static getCancelled({ params }) {
    return client.get("ads/list-cancelled", { params });
  }

  static storeReplies(request) {
    return client.post("ads/add-reply", request);
  }
  static editReplies(request) {
    return client.post("ads/edit-reply", request);
  }
  static lastFreePost(request) {
    return client.get("ads/last-free-post", request);
  }
}
export { AdsService };
