import FullScreenHeader from "common/layouts/Header/FullScreenHeader";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Carousel,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import "../../assets/scss/page/_user_profile.scss";
import ShareIcon from "assets/svgs/ShareIcon";
import ReportIcon from "assets/svgs/ReportIcon";
import image from "assets/images/team2.png";
import ChatIcon from "assets/svgs/ChatIcon";
import UserIcon from "assets/svgs/UserIcon";
import UserProfileNameIcon from "assets/svgs/UserProfileNameIcon";
import UserProfileMailIcon from "assets/svgs/UserProfileMailIcon";
import UserProfilePhoneIcon from "assets/svgs/UserProfilePhoneIcon";
import UserProfileJobIcon from "assets/svgs/UserProfileJobIcon";
import UserProfileWebIcon from "assets/svgs/UserProfileWebIcon";
import UserProfileLocationIcon from "assets/svgs/UserProfileLocationIcon";
import BusinessRole from "assets/svgs/BusinessRole";
import DollarIcon from "assets/svgs/DollarIcon";
import LocationIcon from "assets/svgs/LocationIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import imgsrc from "../../assets/images/no_image.png";
import profilePic from "../../assets/images/no_profile_image.png";

import {
  useGetUserPostList,
  useGetCities,
  useGetStates,
  useGetUserPostCount,
  useAddChatRoom,
  useGetFavorite,
  useAddToFavorite,
  useStoreReportData,
} from "hooks";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ShareReport from "pages/ShareReport/ShareReport";
import LikeButton from "assets/svgs/LikeButton";
import ShareButton from "assets/svgs/ShareButton";
import { useSelector } from "react-redux";
import LikeFillButton from "assets/svgs/LikeFillButton";
import moment from "moment";
import { LOGIN } from "config";
import { CHAT } from "config";
import { CommonHelmet, GridCard } from "common";
import Masonry from "react-masonry-css";

const UserProfileView = ({ t, socket }) => {
  const { state } = useLocation(); // Access the passed state
  const userId = state?.userData;
  let { user_id } = useParams();
  const user = useSelector((state) => state.user);

  const [postList, setPostList] = useState([]);
  const [cityname, setCities] = useState([]);
  const [statename, setStates] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpenRecommendation, setIsPopoverOpenRecommendation] =
    useState(false);

  const [clickedAdId, setClickedAdId] = useState(null);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [openImage, setOpenImage] = useState(null);

  const [reportModal, setReportModal] = useState(false);
  const [reportError, setReportError] = useState();
  const [reportDescription, setReportDescription] = useState("");
  const location = useLocation();
  const popoverRef = useRef(null); // Ref to track popover element
  const buttonRef = useRef(null);
  const buttonRecomRef = useRef(null);
  useEffect(() => {
    if (location.pathname.startsWith("/user-profile-view")) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isShared = queryParams.get("isShared");
    if (!user || !user?.isUserLogin) {
      if (isShared) {
        localStorage.setItem("sharedUserId", user_id);
      }
      navigate(LOGIN);
    }
  }, [user, user_id]);

  const navigate = useNavigate();
  const { refetch: refetch } = useGetUserPostCount(
    { user_id: user_id },
    (res) => {
      setUserData(res?.data?.user_data);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const { refetch: adlistRefetch, isLoading: userPostLoading } =
    useGetUserPostList(
      { user_id: user_id },
      (res) => {
        setPostList(res?.data?.ads_list);
      },
      (error) => {
        toast.error(error.message);
      }
    );

  const { refetch: adCitylistRefetch } = useGetCities(
    (res) => {
      setCities(res?.data?.cities);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const { refetch: adStatelistRefetch } = useGetStates(
    (res) => {
      setStates(res?.data?.states);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const openModal = (adId) => {
    setClickedAdId(adId);
  };

  useEffect(() => {
    adStatelistRefetch();
    adCitylistRefetch();
    adlistRefetch();
    refetch();
  }, [userData, userId]);

  const handleNavigation = (postList) => {
    const advertisement_id = postList?.advertisement_id;
    const advertisement_name = postList?.post_title;

    navigate(
      `/${advertisement_name
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9\s]+/g, "")
        .replace(/\s+/g, "-")
        .replace(/-$/, "")}-${advertisement_id}`
    );
  };

  const getStateName = (stateId) => {
    const state = statename.find((item) => item.state_id == stateId);
    return state ? state.name : "";
  };

  // Function to get the city name by ID
  const getCityName = (cityId) => {
    const city = cityname.find((item) => item.city_id == cityId);
    return city ? city.city : "";
  };

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />{" "}
      {/* Example icon for right arrow */}
    </div>
  );
  const [currentView, setCurrentView] = useState("desktop");

  useEffect(() => {
    const handleMediaChange = () => {
      const isMobile = window.matchMedia("(max-width: 575px)").matches;
      const isLargeDesktop = window.matchMedia("(min-width: 1400px)").matches;
      const isTablet = window.matchMedia(
        "(min-width: 576px) and (max-width: 991px)"
      ).matches;

      if (isMobile) {
        setCurrentView("phone");
      } else if (isTablet) {
        setCurrentView("tablet");
      } else if (isLargeDesktop) {
        setCurrentView("large");
      } else {
        setCurrentView("desktop");
      }
    };

    handleMediaChange();

    window.addEventListener("resize", handleMediaChange);

    return () => window.removeEventListener("resize", handleMediaChange);
  }, []);

  const { mutate: doAddReport } = useStoreReportData(
    (res) => {
      setReportModal(false);
      setReportDescription("");
      toast.success(res?.message);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const handleReportTextChange = (event) => {
    setReportDescription(event.target.value);
  };
  let redirection_url = `/users/view/${user_id}`;

  const handleReportSubmit = () => {
    if (reportDescription === "") {
      setReportError(t("front_page.report_required"));
    } else {
      const reportData = {
        reported_by: user?.user?.user_id,
        reported_id: user_id,
        report_type: 1,
        reason: reportDescription,
        redirection_url: redirection_url,
      };
      if (reportDescription.length < 255) {
        doAddReport(reportData);
      } else {
        toast.error(t("front_page.report_text_max_length"));
      }
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const isDefaultImage = (url) => {
    return url?.endsWith("default.jpeg"); // Check if the image URL ends with 'default.jpeg'
  };

  // Determine the image source
  const profileImage =
    userData?.profile_image && !isDefaultImage(userData?.profile_image)
      ? userData?.profile_image
      : profilePic;

  const popoverContent = (
    <Popover id="popover-basic" ref={popoverRef}>
      <ShareReport
        t={t}
        url={`${process.env.REACT_APP_SHARE_USER_URL}/${user?.user?.user_id}`}
        setIsPopoverOpen={setIsPopoverOpen}
        userEmail={user?.user?.email}
      />
    </Popover>
  );

  const popoverContentRecommendes = (clickedAdId) => {
    return (
      <Popover id="popover-share">
        <ShareReport
          t={t}
          url={`${process.env.REACT_APP_SHARE_URL}/${clickedAdId}`}
          setIsPopoverOpen={setIsPopoverOpen}
        />
      </Popover>
    );
  };
  const { mutate: createRoom } = useAddChatRoom(
    (res) => {
      if (res?.data) {
        const { sender_id, receiver_id, chat_room_id } = res?.data?.chat_room;
        const sender_name = user?.user?.full_name;

        // Make sure all necessary values are defined
        if (sender_id && receiver_id && chat_room_id && sender_name) {
          socket.emit("create_room", {
            chat_room_id,
            sender_id,
            receiver_id,
            sender_name,
            sender_profile: user?.user?.profile_image,
            time: moment(new Date()).format("hh:mm A"),
            date: moment(new Date()).format("DD/MM/YYYY"),
          });

          navigate(CHAT, {
            state: { sender_id, chat_room_id },
          });
        } else {
          console.error("Missing data in response:", res?.data);
        }
      } else {
        console.error("Response data is missing or invalid:", res);
      }
    },
    (error) => {
      console.error("Error creating chat room:", error);
      // Optionally, show user feedback for error
      // Example: setError("Failed to create chat room. Please try again.");
    }
  );

  useEffect(() => {
    const handleScroll = () => {
      if (isPopoverOpen) {
        setIsPopoverOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPopoverOpen, setIsPopoverOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current == null &&
        !buttonRef?.current?.contains(event.target)
      ) {
        setIsPopoverOpen(false); // Close popover if clicked outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen, setIsPopoverOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (openPopoverId) {
        setOpenPopoverId(null); // Close popover on scroll
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [openPopoverId, setOpenPopoverId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRecomRef.current &&
        !buttonRecomRef.current.contains(event.target)
      ) {
        setOpenPopoverId(null);
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenPopoverId, openPopoverId]);
  const handleCreateRoom = async (userData) => {
    if (!user?.isUserLogin) {
      toast.error(t("front_page.user_not_logged_in_message"));
    } else {
      const sender_id = user?.user?.user_id;
      const receiver_id = userData?.user_id;
      await createRoom({ receiver_id });
    }
  };

  const [favorites, setFavorites] = useState([]);
  const { mutate: doAddFavorite } = useAddToFavorite(
    (res) => {
      getFavoriteList();
    },
    (error) => {
      console.error("error: ", error);
    }
  );
  const { refetch: getFavoriteList } = useGetFavorite(
    { user_id: user?.user?.user_id },
    (res) => {
      setFavorites(res?.data?.favorites);
    },
    (error) => {
      console.error("error: ", error);
    }
  );

  const handleFavorite = (advertisement_id) => {
    const user_id = user?.user?.user_id;
    if (user?.isUserLogin === false) {
      toast.error(t("front_page.do_login_fav_post"));
    } else {
      doAddFavorite({ advertisement_id, user_id });
    }
  };
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };
  return (
    <>
      <CommonHelmet
        title={"front_page.user_profile_view_seo_title"}
        description={"front_page.user_profile_view_seo_title"}
      />
      <FullScreenHeader></FullScreenHeader>
      <Container fluid className="p-5" style={{ maxWidth: "1206.5px" }}>
        <Row className="p-3 user-profile-main-container p-md-5 ">
          <Col
            md={12}
            className="d-flex align-items-center justify-content-between username-share"
          >
            <span className="user-profile-title">
              {userData?.businessDetails !== null &&
              userData?.businessDetails?.status === 1
                ? t("front_page.business_profile_title")
                : t("front_page.user_profile_title")}
            </span>
            <span className="d-flex align-items-center gap-3">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popoverContent}
                rootClose={true}
                container={this}
                show={isPopoverOpen}
                onEntering={() => setIsPopoverOpen(true)} // Open the popover
                onExit={() => setIsPopoverOpen(false)}
                rootCloseEvent="click" // Close on exit
              >
                <Button
                  className="share-btn p-2 px-3 position-relative"
                  onClick={() => setIsPopoverOpen(true)}
                  ref={buttonRef}
                >
                  <ShareIcon />
                  {t("front_page.share_icon")}
                </Button>
              </OverlayTrigger>

              {/* Modal content */}

              <Button
                className="report-btn p-2 px-3"
                onClick={() => {
                  if (user?.isUserLogin === false) {
                    toast.error(t("front_page.please_do_login"));
                  } else {
                    setReportModal(true);
                  }
                }}
              >
                <ReportIcon /> {t("front_page.report_icon")}
              </Button>

              {/* Modal Overlay */}
              <Modal
                show={reportModal}
                onHide={() => {
                  setReportModal(false);
                  setReportDescription("");
                }}
                centered
                className="deactivate-modal"
              >
                <Modal.Header
                  closeButton
                  className="d-flex border-0 pt-0 report_modal"
                >
                  <Modal.Title className="mt-4 w-100">
                    <div className="d-flex justify-content-center fs-2">
                      {t("front_page.report_user_modal_title")}
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="fs-2 border-0 text-center">
                  <Form.Group id="reportModal">
                    <FormControl
                      as="textarea"
                      rows={5}
                      placeholder={t("front_page.enter_report_placeholder")}
                      className="report-desc-textarea mt-3 text-light bg-dark"
                      value={reportDescription}
                      onChange={handleReportTextChange}
                      // maxLength={500}
                    ></FormControl>
                  </Form.Group>
                </Modal.Body>
                {reportDescription === "" && (
                  <div style={{ color: "red", paddingLeft: "34px" }}>
                    {reportError}
                  </div>
                )}
                <Modal.Footer className="border-0">
                  <Button
                    variant="danger"
                    onClick={() => handleReportSubmit()}
                    // disabled={reportDescription === ""}
                  >
                    {t("front_page.report_submit_button")}
                  </Button>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setReportModal(false);
                      setReportDescription("");
                    }}
                  >
                    {t("front_page.report_cancel_button")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </span>
          </Col>
          <Col
            md={12}
            className="d-flex gap-5 mt-4 align-items-center align-items-md-start user-info-main-box pb-5"
          >
            <div className="user-info">
              <span className="user-image-div">
                <img src={profileImage}></img>
              </span>
              {user_id != user?.user?.user_id && (
                <Button
                  className="chat-btn px-4 py-2"
                  onClick={() => handleCreateRoom(userData)}
                >
                  <ChatIcon /> {t("front_page.chat_title")}
                </Button>
              )}
            </div>

            <div className="user-details">
              {/* name */}
              <div className="detail-row mb-4">
                <UserProfileNameIcon /> {userData?.full_name}
              </div>
              {/* email */}
              {userData?.businessDetails !== null &&
                userData?.businessDetails?.status === 1 && (
                  <div className="detail-row mb-4">
                    <UserProfileMailIcon /> {userData?.email}
                  </div>
                )}
              {/* contact */}
              {userData?.businessDetails !== null &&
                userData?.businessDetails?.status === 1 &&
                userData?.mobile_number && (
                  <div className="detail-row mb-4">
                    <UserProfilePhoneIcon /> {userData?.country_code_id}{" "}
                    {userData?.mobile_number}
                  </div>
                )}
              {userData?.businessDetails &&
                userData?.businessDetails?.status === 1 && (
                  <>
                    {/* job */}

                    <div className="detail-row mb-4">
                      <UserProfileJobIcon />
                      {userData?.businessDetails?.business_name}
                    </div>
                    {/* Business role */}
                    <div className="detail-row mb-4">
                      <BusinessRole />
                      {userData?.businessDetails?.business_role !== null &&
                        userData?.businessDetails?.business_role}
                    </div>
                    {/* website */}
                    <div className="detail-row mb-4">
                      <UserProfileWebIcon />{" "}
                      {userData?.businessDetails?.web_url}
                    </div>
                    {/* Location */}
                    <div className="detail-row mb-4">
                      <UserProfileLocationIcon />
                      {userData?.businessDetails?.location}
                    </div>
                  </>
                )}
            </div>
          </Col>
          <Col md={12} className="mt-4 user-prof-grid">
            <span className="user-profile-title">
              {t("front_page.ad_post_title")}
            </span>
            <Row className="card-main-row pt-2 grid">
              {/* grid-card */}
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {postList.length > 0 &&
                  postList.map((ads, index) => {
                    return (
                      <GridCard
                        className={"grid-card"}
                        key={index}
                        postList={ads}
                        favorites={favorites}
                        user={user}
                        handleFavorite={handleFavorite}
                        handleNavigation={handleNavigation}
                        t={t}
                        imgSrc={imgsrc}
                        getCityName={getCityName}
                        // getStateName={getStateName}
                        // parentCategoryName={parentCategoryName}
                      />
                      // <div className="grid-card">
                      //   <Col className="grid-card-sub" key={index}>
                      //     {ads?.advertisements_image?.length > 0 ? (
                      //       ads?.advertisements_image.length > 1 ? (
                      //         <Carousel interval={null}>
                      //           {ads?.advertisements_image.map(
                      //             (imageValue, index) => (
                      //               <Carousel.Item key={index}>
                      //                 <div
                      //                   className="img-div-multiimage"
                      //                   key={index}
                      //                 >
                      //                   <img
                      //                     src={imageValue?.image_url}
                      //                     alt={`Preview ${index + 1}`}
                      //                     onClick={() => {
                      //                       handleAds(
                      //                         ads?.advertisement_id,
                      //                         ads?.post_title
                      //                       );
                      //                     }}
                      //                     className="grid-img"
                      //                     style={{ filter: "blur(10px)" }}
                      //                     onLoad={(e) =>
                      //                       (e.target.style.filter = "none")
                      //                     }
                      //                     loading="lazy"
                      //                   />
                      //                   <div className="button-div">
                      //                     <div
                      //                       data-toggle="tooltip"
                      //                       data-placement="center"
                      //                       className="like-btn"
                      //                       title={t(
                      //                         "front_page.tool_tip_favorite"
                      //                       )}
                      //                       onClick={() =>
                      //                         handleFavorite(
                      //                           ads?.advertisement_id
                      //                         )
                      //                       }
                      //                     >
                      //                       {(favorites &&
                      //                         favorites
                      //                           .map((fav) => {
                      //                             // Check if advertisement_id and user_id match
                      //                             if (
                      //                               fav?.advertisement_id ==
                      //                                 ads?.advertisement_id &&
                      //                               fav?.user_id ==
                      //                                 user?.user?.user_id
                      //                             ) {
                      //                               // Render the LikeFillButton if there's a match
                      //                               return (
                      //                                 <LikeFillButton
                      //                                   key={
                      //                                     fav?.advertisement_id
                      //                                   }
                      //                                 />
                      //                               );
                      //                             }
                      //                             return null; // Return null if no match, so nothing gets rendered
                      //                           })
                      //                           .find(Boolean)) || (
                      //                         <LikeButton key="no-match" />
                      //                       )}
                      //                     </div>
                      //                     <OverlayTrigger
                      //                       trigger="click"
                      //                       placement="bottom"
                      //                       overlay={popoverContentRecommendes(
                      //                         ads?.advertisement_id
                      //                       )}
                      //                       rootClose={true}
                      //                       container={this}
                      //                       show={
                      //                         openPopoverId ===
                      //                           ads?.advertisement_id &&
                      //                         openImage === index
                      //                       }
                      //                       onEntering={() =>
                      //                         setOpenPopoverId(
                      //                           ads?.advertisement_id
                      //                         )
                      //                       } // Set the clicked ad's ID to open the popover
                      //                       onExit={() => {
                      //                         setOpenPopoverId(null);
                      //                         setOpenImage(null);
                      //                       }}
                      //                       rootCloseEvent="click"
                      //                     >
                      //                       <div
                      //                         className="share-btn-grid"
                      //                         data-toggle="tooltip"
                      //                         data-placement="center"
                      //                         title={t(
                      //                           "front_page.tool_tip_share"
                      //                         )}
                      //                         ref={buttonRecomRef}
                      //                         onClick={() => {
                      //                           openModal(ads?.advertisement_id);
                      //                           if (
                      //                             openPopoverId ===
                      //                               ads?.advertisement_id &&
                      //                             openImage === index
                      //                           ) {
                      //                             setOpenPopoverId(null);
                      //                             setOpenImage(null);
                      //                           } else {
                      //                             setOpenPopoverId(
                      //                               ads?.advertisement_id
                      //                             );
                      //                             setOpenImage(index);
                      //                           }
                      //                         }}
                      //                       >
                      //                         <ShareButton />
                      //                       </div>
                      //                     </OverlayTrigger>
                      //                   </div>
                      //                 </div>
                      //               </Carousel.Item>
                      //             )
                      //           )}
                      //         </Carousel>
                      //       ) : (
                      //         <div className="img-div">
                      //           <img
                      //             src={ads.advertisements_image[0]?.image_url}
                      //             alt="Preview"
                      //             onClick={() => {
                      //               handleAds(
                      //                 ads?.advertisement_id,
                      //                 ads?.post_title
                      //               );
                      //             }}
                      //             className="grid-img"
                      //             style={{ filter: "blur(10px)" }}
                      //             onLoad={(e) => (e.target.style.filter = "none")}
                      //             loading="lazy"
                      //           />
                      //           <div className="button-div">
                      //             <div
                      //               data-toggle="tooltip"
                      //               data-placement="center"
                      //               className="like-btn"
                      //               title={t("front_page.tool_tip_favorite")}
                      //               onClick={() =>
                      //                 handleFavorite(ads?.advertisement_id)
                      //               }
                      //             >
                      //               {(favorites &&
                      //                 favorites
                      //                   .map((fav) => {
                      //                     // Check if advertisement_id and user_id match
                      //                     if (
                      //                       fav?.advertisement_id ==
                      //                         ads?.advertisement_id &&
                      //                       fav?.user_id == user?.user?.user_id
                      //                     ) {
                      //                       // Render the LikeFillButton if there's a match
                      //                       return (
                      //                         <LikeFillButton
                      //                           key={fav?.advertisement_id}
                      //                         />
                      //                       );
                      //                     }
                      //                     return null; // Return null if no match, so nothing gets rendered
                      //                   })
                      //                   .find(Boolean)) || (
                      //                 <LikeButton key="no-match" />
                      //               )}
                      //             </div>
                      //             <OverlayTrigger
                      //               trigger="click"
                      //               placement="bottom"
                      //               overlay={popoverContentRecommendes(
                      //                 ads?.advertisement_id
                      //               )}
                      //               rootClose={true}
                      //               container={this}
                      //               show={openPopoverId === ads?.advertisement_id}
                      //               onEntering={() =>
                      //                 setOpenPopoverId(ads?.advertisement_id)
                      //               } // Set the clicked ad's ID to open the popover
                      //               onExit={() => setOpenPopoverId(null)}
                      //               rootCloseEvent="click"
                      //             >
                      //               <div
                      //                 className="share-btn-grid"
                      //                 data-toggle="tooltip"
                      //                 data-placement="center"
                      //                 title={t("front_page.tool_tip_share")}
                      //                 ref={buttonRecomRef}
                      //                 onClick={() => {
                      //                   openModal(ads?.advertisement_id);
                      //                   if (
                      //                     openPopoverId === ads?.advertisement_id
                      //                   ) {
                      //                     setOpenPopoverId(null);
                      //                   } else {
                      //                     setOpenPopoverId(ads?.advertisement_id);
                      //                   }
                      //                 }}
                      //               >
                      //                 <ShareButton />
                      //               </div>
                      //             </OverlayTrigger>
                      //           </div>
                      //         </div>
                      //       )
                      //     ) : (
                      //       <div className="img-div">
                      //         <img
                      //           src={imgsrc}
                      //           alt="Placeholder"
                      //           className="grid-img"
                      //           onClick={() => {
                      //             handleAds(
                      //               ads?.advertisement_id,
                      //               ads?.post_title
                      //             );
                      //           }}
                      //           style={{ filter: "blur(10px)" }}
                      //           onLoad={(e) => (e.target.style.filter = "none")}
                      //           loading="lazy"
                      //         />
                      //         <div className="button-div">
                      //           <div
                      //             data-toggle="tooltip"
                      //             data-placement="center"
                      //             className="like-btn"
                      //             title={t("front_page.tool_tip_favorite")}
                      //             onClick={() =>
                      //               handleFavorite(ads?.advertisement_id)
                      //             }
                      //           >
                      //             {(favorites &&
                      //               favorites
                      //                 ?.map((fav) => {
                      //                   // Check if advertisement_id and user_id match
                      //                   if (
                      //                     fav?.advertisement_id ==
                      //                       ads?.advertisement_id &&
                      //                     fav?.user_id == user?.user?.user_id
                      //                   ) {
                      //                     // Render the LikeFillButton if there's a match
                      //                     return (
                      //                       <LikeFillButton
                      //                         key={fav?.advertisement_id}
                      //                       />
                      //                     );
                      //                   }
                      //                   return null; // Return null if no match, so nothing gets rendered
                      //                 })
                      //                 .find(Boolean)) || (
                      //               <LikeButton key="no-match" />
                      //             )}
                      //           </div>
                      //           <OverlayTrigger
                      //             trigger="click"
                      //             placement="bottom"
                      //             overlay={popoverContentRecommendes(
                      //               ads?.advertisement_id
                      //             )}
                      //             rootClose={true}
                      //             show={openPopoverId === ads?.advertisement_id}
                      //             onEntering={() =>
                      //               setOpenPopoverId(ads?.advertisement_id)
                      //             } // Set the clicked ad's ID to open the popover
                      //             onExit={() => setOpenPopoverId(null)}
                      //             rootCloseEvent="click"
                      //           >
                      //             <div
                      //               className="share-btn-grid"
                      //               data-toggle="tooltip"
                      //               data-placement="center"
                      //               title={t("front_page.tool_tip_share")}
                      //               ref={buttonRecomRef}
                      //               onClick={() => {
                      //                 openModal(ads?.advertisement_id);
                      //                 if (
                      //                   openPopoverId === ads?.advertisement_id
                      //                 ) {
                      //                   setOpenPopoverId(null); // Close popover if it's already open
                      //                 } else {
                      //                   setOpenPopoverId(ads?.advertisement_id); // Open popover for the clicked ad
                      //                 }
                      //               }}
                      //             >
                      //               <ShareButton />
                      //             </div>
                      //           </OverlayTrigger>
                      //         </div>
                      //       </div>
                      //     )}
                      //     <div
                      //       className="job-name mt-3"
                      //       onClick={() => {
                      //         handleAds(ads?.advertisement_id, ads?.post_title);
                      //       }}
                      //     >
                      //       {ads?.post_title}
                      //     </div>
                      //     <div>
                      //       {ads?.advertisements_variation?.length > 0 &&
                      //         ads?.advertisements_variation.map(
                      //           (attribute, indx) => {
                      //             const type =
                      //               attribute?.attribute?.attribute_type;
                      //             if (type === 8 && type !== undefined) {
                      //               return (
                      //                 <React.Fragment key={indx}>
                      //                   <div className="job-price mt-3">
                      //                     {attribute?.value !== null &&
                      //                       attribute?.value !== "" && (
                      //                         <>
                      //                           <DollarIcon className="custom-dollar-icon" />
                      //                           {attribute?.value}
                      //                         </>
                      //                       )}
                      //                   </div>
                      //                 </React.Fragment>
                      //               );
                      //             }
                      //           }
                      //         )}
                      //       <span className="loc-apply-wrapper mb-1">
                      //         <div className="location-name mt-2">
                      //           <LocationIcon></LocationIcon>
                      //           <span className="ms-1">
                      //             {getCityName(ads?.city) &&
                      //             getStateName(ads?.state)
                      //               ? `${getCityName(ads?.city)}, ${getStateName(
                      //                   ads?.state
                      //                 )}`
                      //               : getCityName(ads?.city)
                      //               ? getCityName(ads?.city)
                      //               : getStateName(ads?.state)}
                      //           </span>
                      //         </div>
                      //         {ads?.advertisements_variation.map(
                      //           (category, inx) => {
                      //             const type =
                      //               category?.attribute?.attribute_type;
                      //             if (
                      //               type === 7 &&
                      //               category.value != "" &&
                      //               type !== undefined
                      //             ) {
                      //               return (
                      //                 <React.Fragment key={inx}>
                      //                   <Button
                      //                     className="apply-btn px-3"
                      //                     onClick={() =>
                      //                       ads?.category_name !== "For Sale" &&
                      //                       window.open(category.value, "_blank")
                      //                     }
                      //                   >
                      //                     {ads?.category_name === "For Sale"
                      //                       ? t("front_page.buy_button_text")
                      //                       : ads?.category_name === "Resumes"
                      //                       ? t("front_page.intro_button_text")
                      //                       : t("front_page.apply_button_text")}
                      //                   </Button>
                      //                 </React.Fragment>
                      //               );
                      //             }
                      //           }
                      //         )}
                      //       </span>
                      //     </div>
                      //   </Col>
                      // </div>
                    );
                  })}
              </Masonry>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfileView;
