import DollarIcon from "assets/svgs/DollarIcon";
import LikeButton from "assets/svgs/LikeButton";
import LikeFillButton from "assets/svgs/LikeFillButton";
import LocationIcon from "assets/svgs/LocationIcon";
import React, { useEffect } from "react";
import { Col, Button, Carousel, Figure } from "react-bootstrap";
import LazyImage from "./LazyImage";

const GridCard = ({
  adsData,
  className = "",
  postList,
  favorites = [],
  user,
  handleFavorite,
  handleNavigation,
  t,
  imgSrc,
  getCityName = () => "",
  getStateName = () => "",
  parentCategoryName = "",
}) => {
  const isGetCityNameDefault = getCityName.toString() === (() => "").toString();
  const isGetStateNameDefault =
    getStateName.toString() === (() => "").toString();
  const renderCarousel = (images) => {
    return (
      <Carousel interval={null}>
        {images.map((imageValue, index) => (
          <Carousel.Item key={index}>
            <div className="img-div-multiimage">
              <LazyImage
                alt={`Preview ${index + 1}`}
                postList={postList}
                src={imageValue}
              />
              <div className="button-div grid-like-btn">
                <div
                  data-toggle="tooltip"
                  data-placement="center"
                  className="like-btn"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => handleFavorite(postList?.advertisement_id)}
                  title={t("front_page.tool_tip_favorite")}
                >
                  {renderLikeButton(postList, favorites, user)}
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const renderLikeButton = (postList, favorites, user) => {
    const favorite = favorites.find(
      (fav) =>
        fav?.advertisement_id === postList?.advertisement_id &&
        fav?.user_id === user?.user?.user_id
    );
    return favorite ? <LikeFillButton /> : <LikeButton />;
  };

  const renderPrice = (variations) => {
    return variations?.map((value, index) => {
      if (value?.attribute?.attribute_type === 8) {
        return (
          <div className="job-price mt-3" key={index}>
            {value?.value !== null && value?.value !== "" && (
              <>
                <DollarIcon className="custom-dollar-icon" />
                {value?.value}
              </>
            )}
          </div>
        );
      }
      return null;
    });
  };

  const renderLocation = (postList) => {
    const city = !isGetCityNameDefault
      ? getCityName(postList?.city)
      : postList?.Advertisement?.city_name;
    const state = !isGetStateNameDefault
      ? getStateName(postList?.state)
      : postList?.Advertisement?.state_name;
    const location = city && state ? `${city}, ${state}` : city || state;

    return (
      <div className="location-name location-grid mt-2">
        <LocationIcon />
        <span className="ms-1">{location}</span>
      </div>
    );
  };

  const renderApplyButton = (postList) => {
    const variations =
      postList?.advertisements_variation !== undefined
        ? postList?.advertisements_variation
        : postList?.variations;
    const category =
      parentCategoryName !== "" ? parentCategoryName : postList?.category_name;
    return variations?.map((value, index) => {
      if (value?.attribute?.attribute_type === 7 && value?.value) {
        return (
          <Button
            key={index}
            className="apply-btn px-3 ms-2"
            onClick={() => window.open(value.value, "_blank")}
          >
            {category === "For Sale"
              ? t("front_page.buy_button_text")
              : category === "Resumes"
              ? t("front_page.intro_button_text")
              : t("front_page.apply_button_text")}
          </Button>
        );
      }
      return null;
    });
  };
  let imageUrls = [];
  const imagesData = postList?.advertisements_image || postList?.images;
  if (imagesData) {
    if (Array.isArray(imagesData)) {
      // If imagesData is an array of objects (like advertisements_image), extract image_url
      if (imagesData[0]?.image_url) {
        imageUrls = imagesData.map((item) => item.image_url);
      }
      // If imagesData is an array of strings (like images), use them directly
      else if (typeof imagesData[0] === "string") {
        imageUrls = imagesData;
      }
    }
  }
  return (
    <div className={className} key={postList.id}>
      <Col className="grid-card-sub">
        {imageUrls?.length > 1
          ? renderCarousel(imageUrls)
          : imageUrls?.length === 1 && (
              <div className="img-div">
                <LazyImage
                  alt={`Preview`}
                  postList={postList}
                  src={imageUrls[0]}
                />
                <div className="button-div grid-like-btn">
                  <div
                    data-toggle="tooltip"
                    data-placement="center"
                    className="like-btn"
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => handleFavorite(postList?.advertisement_id)}
                    title={t("front_page.tool_tip_favorite")}
                  >
                    {renderLikeButton(postList, favorites, user)}
                  </div>
                </div>
              </div>
            )}
        {!imageUrls?.length && (
          <div className="img-div">
            <img
              src={imgSrc}
              alt="Placeholder"
              className="grid-img"
              onClick={() => handleNavigation(postList)}
              style={{ filter: "blur(10px)" }}
              onLoad={(e) => (e.target.style.filter = "none")}
              loading="lazy"
            />
            <div className="button-div">
              <div
                data-toggle="tooltip"
                data-placement="center"
                className="like-btn"
                style={{ backgroundColor: "transparent" }}
                onClick={() => handleFavorite(postList?.advertisement_id)}
                title={t("front_page.tool_tip_favorite")}
              >
                {renderLikeButton(postList, favorites, user)}
              </div>
            </div>
          </div>
        )}
        <div className="job-name mt-3">
          <a
            href="#"
            className="job-title job-title-grid text-decoration-none"
            style={{ color: "white" }}
            onClick={(e) => {
              e.preventDefault();
              handleNavigation(postList);
            }}
          >
            <span>{postList?.post_title}</span>
          </a>
        </div>
        {renderPrice(postList?.advertisements_variation)}
        <span className="loc-apply-wrapper mb-1">
          {renderLocation(postList)}
          {renderApplyButton(postList)}
        </span>
      </Col>
    </div>
  );
};

export default GridCard;
