import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import "assets/scss/page/_ads.scss";

// Fix marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const greenIcon = new L.Icon({
  iconUrl: require("../../assets/images/map_pin.png"),
  iconRetinaUrl: require("../../assets/images/map_pin_2x.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const AddMarkerOnClick = ({ setMarker, setMapLocation }) => {
  useMapEvents({
    click(event) {
      const { lat, lng } = event.latlng;
      // Set a single marker by replacing the previous marker
      setMarker({ lat, lng });
      setMapLocation({ lat, lng });
    },
  });
  return null;
};

const MapComponent = ({
  setMapLocation,
  initialCoordinates,
  isView,
  listMap,
}) => {
  const initialLatLong =
    initialCoordinates.lat != undefined && initialCoordinates.lng != undefined
      ? initialCoordinates
      : null;

  const [marker, setMarker] = useState(initialLatLong);
  const [center, setCenter] = useState([0, 0]);
  const prevInitialLatLong = useRef();
  useEffect(() => {
    const newCenter = initialLatLong;
    if (newCenter != null) {
      const prevLatLong = prevInitialLatLong?.current;
      if (
        !prevLatLong ||
        newCenter?.lat != prevLatLong?.lat ||
        newCenter?.lng != prevLatLong?.lng
      ) {
        setMarker(newCenter);
        setMapLocation(newCenter);
        setCenter([newCenter?.lat, newCenter?.lng]);

        prevInitialLatLong.current = newCenter;
      }
    }
  }, [initialLatLong]);

  return center[0] == 0 ? null : (
    <MapContainer
      center={[center[0], center[1]]}
      zoom={12}
      style={{ height: "300px", width: `${listMap ? "75%" : "100%"}` }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; collegely - Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      {!isView && (
        <AddMarkerOnClick
          setMarker={setMarker}
          setMapLocation={setMapLocation}
        />
      )}
      {marker && (
        <Marker position={[marker.lat, marker.lng]} icon={greenIcon}>
          <Popup>
            A marker at [{marker.lat}, {marker.lng}]
          </Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapComponent;
