import FullScreenHeader from "common/layouts/Header/FullScreenHeader";
import FullScreenLeftMenu from "common/layouts/Sidebar/FullScreenLeftMenu";
import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import ViewAdsTabs from "./ViewAdsTabs";
import {
  useGetParentCategoryList,
  useGetChildCategoryList,
  useGetAdverstisementList,
  useGetStates,
  useAttributeList,
  useGetCitiesByState,
  useGetPostSettingData,
} from "hooks";
import { useLocation } from "react-router-dom";

function PostAdsList({ getSettingData, children, t, getSeoData }) {
  const [sharedVariable, setSharedVariable] = useState("");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Dark background
      borderColor: "#4d4d4d", // Dark border
      borderWidth: "1px",
      borderRadius: "10px",
      minWidth: "160px",
      height: "40px",
      boxShadow: "none", // Remove shadow
      color: "#fff", // White text
      "&:hover": {
        borderColor: "#2e2d2d", // Lighter border on hover
        color: "#fff", // White text
      },
      zIndex: 1000,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Dark background for menu
      color: "#fff", // White text
      // Hide scrollbar
      overflowY: "hidden", // Hide vertical scrollbar
      maxHeight: "190px",
    }),
    menuList: (provided) => ({
      ...provided,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#0A5640",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      overflowX: "hidden",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#2e2d2d"
        : state.isFocused
        ? "#2e2d2d"
        : "#2e2d2d", // Dark background for options
      color: "#fff", // White text

      "&:hover": {
        backgroundColor: "#2e2d2d", // Lighter background on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#ffff", // White text for selected value
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgb(177, 177, 177)", // White text
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Dark indicator separator
      // color: "#0000",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#fff", // White dropdown indicator
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff", // White text color in the search input
    }),
  };
  const location = useLocation();
  const [parentCategory, setParentCategory] = useState(
    localStorage.advertisementList !== undefined &&
      localStorage.advertisementList !== ""
      ? JSON.parse(localStorage.advertisementList).parentCategory
      : ""
  );
  const [childCategory, setChildCategory] = useState(
    localStorage.advertisementList != undefined &&
      localStorage.advertisementList != ""
      ? JSON.parse(localStorage.advertisementList).childCategory
      : ""
  );
  const [selectedCity, setSelectedCity] = useState(
    localStorage.city_data !== undefined && localStorage.city_data !== ""
      ? JSON.parse(localStorage.city_data).city_id
      : ""
  );
  const [selectedState, setSelectedState] = useState(
    localStorage.city_data !== undefined && localStorage.city_data !== ""
      ? JSON.parse(localStorage.city_data).state_id
      : ""
  );
  const [advertisementdata, setAdvertisementList] = useState(
    localStorage.advertisementList !== undefined &&
      localStorage.advertisementList !== ""
      ? JSON.parse(localStorage.advertisementList).advertisementdata
      : ""
  );

  const [filterName, setFilterByDate] = useState(() => {
    const storedData = localStorage.advertisementList
      ? JSON.parse(localStorage.advertisementList)
      : {};
    return storedData.filterName || ""; // Default to 1 if no stored value
  });
  const [searchText, setSearchText] = useState();
  const [cityName, selectedCityName] = useState(
    localStorage.advertisementList != undefined &&
      localStorage.advertisementList != ""
      ? JSON.parse(localStorage.advertisementList).cityName
      : ""
  );
  const [checkedItems, setCheckedItems] = useState(
    localStorage.advertisementList !== undefined &&
      localStorage.advertisementList !== ""
      ? JSON.parse(localStorage.advertisementList).checkedItems
      : []
  );
  const [attribute, setAttribute] = useState(
    localStorage.advertisementList !== undefined &&
      localStorage.advertisementList !== ""
      ? JSON.parse(localStorage.advertisementList).attribute
      : ""
  );
  const [checkedAttributes, setcheckedAttributes] = useState(
    localStorage.advertisementList !== undefined &&
      localStorage.advertisementList !== ""
      ? JSON.parse(localStorage.advertisementList).checkedAttributes
      : []
  );
  const [cityStateChange, setCityStateChange] = useState(
    localStorage.city_data !== undefined && localStorage.city_data !== ""
      ? JSON.parse(localStorage.city_data).state_id
      : ""
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAds, setTotalAds] = useState(0);
  const [limit, setLimit] = useState(100);
  const [isChanged, setIsChanged] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [clearSubCategory, setClearCategory] = useState(false);
  const [initialData, setInitialAdvertisements] = useState({});
  const [isValueSet, setIsValueSet] = useState(false);
  const [stateName, setStateName] = useState("");
  const [stateChange, setStateChange] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const initialFilters = {
    sub_category_ids: [],
    checked_attribute_ids: [],
  };

  const [filteredAdvertisements, setFilteredAdvertisements] = useState({});

  // This will store the previous location
  const [prevLocation, setPrevLocation] = useState(null);
  useEffect(() => {
    if (prevLocation && location.key === prevLocation.key) {
      handleReset();
      setCheckedItems([]);
      setAttribute([]);
    }
    // Update the prevLocation with the current location
    setPrevLocation(location);
  }, [location, prevLocation]);
  useEffect(() => {
    if (!isChanged) {
      const category = localStorage.getItem("category")
        ? JSON.parse(localStorage.getItem("category"))
        : "";
      const categorydata = location?.state?.category || {};
      const fetchCategory = category ? category : categorydata;
      if (fetchCategory) {
        setChildCategory(
          fetchCategory.parent_id ? fetchCategory?.category_id : ""
        );

        setParentCategory(
          fetchCategory?.parent_id
            ? fetchCategory?.parent_id
            : fetchCategory?.category_id
        );
        // localStorage.removeItem("category");
      }
      const fetchLocation = localStorage.getItem("headerlocation");
      const cityData = JSON.parse(localStorage.getItem("city_data")) || {};
      let locationData = "";
      if (fetchLocation) {
        locationData = JSON.parse(fetchLocation);
      }
      const fetchPostData = cityData ? cityData : locationData;

      if (fetchPostData) {
        if (!selectedCity) {
          setSelectedCity(fetchPostData?.city_id);
        }
        if (!cityName) {
          selectedCityName(fetchPostData?.name);
        }
        if (!selectedState) {
          setSelectedState(fetchPostData?.state_id);
          setCityStateChange(fetchPostData?.state_id);
        }
        localStorage.removeItem("headerlocation");
      }
      setIsValueSet(true);
    }
  }, [isChanged]);

  // const cityData = JSON.parse(localStorage.getItem("city_data")) || {};
  // setSelectedState(cityData?.state_id);
  // setCityStateChange(cityData?.state_id);

  useEffect(() => {
    let advertisementList = {
      searchText: searchText,
      advertisementLIst: advertisementdata,
      selectedState: selectedState,
      selectedCity: selectedCity,
      parentCategory: parentCategory,
      childCategory: childCategory,
      sortOrder: filterName,
      checkedItems: checkedItems,
      attribute: attribute,
      checkedAttributes: checkedAttributes,
      cityName: cityName,
      currentPage: currentPage,
      limit: limit,
    };
    // localStorage.advertisementList = JSON.stringify(advertisementList);
    fetchAttributeList();
  }, [
    searchText,
    advertisementdata,
    selectedState,
    selectedCity,
    parentCategory,
    childCategory,
    filterName,
    checkedItems,
    attribute,
    checkedAttributes,
    cityName,
    currentPage,
    limit,
  ]);

  //parent category name
  const { data: parent_category_list } = useGetParentCategoryList();

  //state fetch api
  const { data: state_list } = useGetStates();

  //city fetch api
  const { data: city_list } = useGetCitiesByState({
    state_id: cityStateChange,
  });
  //child category name
  const { data: child_category_list, refetch: refetchChildCategory } =
    useGetChildCategoryList({
      parent_id: parentCategory,
      city: selectedCity,
      state: selectedState,
    });

  const handleApply = () => {
    setCurrentPage(1);
    setIsApplied(true);
    let filteredData = {
      sub_category_ids: checkedItems,
      checked_attribute_ids: checkedAttributes,
    };
    if (checkedItems.length > 0) {
      setChildCategory("");
    }
    refetchAdvertisement();
    setFilteredAdvertisements(filteredData);
  };

  // useEffect(
  //   () => {
  //     // First load or when filters change
  //     const initialFilters = {
  //       sub_category_ids: checkedItems,
  //       checked_attribute_ids: checkedAttributes,
  //     };
  //     if (isValueSet) {
  //       setInitialAdvertisements(initialFilters);
  //       refetchAdvertisement();
  //       setIsValueSet(false);
  //     }
  //     if (isChanged) {
  //       setFilteredAdvertisements({});
  //     }
  //   },
  //   [
  //     // // isApplied,
  //     // parentCategory,
  //     // // childCategory,
  //     // selectedCity,
  //     // searchText,
  //     // // checkedItems,
  //     // checkedAttributes,
  //     // filterName,
  //     // currentPage,
  //     // limit,
  //     // isValueSet,
  //   ]
  // );
  const { refetch: refetchAdvertisement, isLoading: isAdsLoading } =
    useGetAdverstisementList(
      {
        category_id: parentCategory,
        sub_category_id: childCategory,
        state: selectedState,
        city: selectedCity,
        searchText: debouncedSearchText,
        sub_category_ids: clearSubCategory
          ? []
          : isApplied
          ? filteredAdvertisements?.sub_category_ids
          : initialData?.sub_category_ids,
        checked_attribute_ids: isApplied
          ? filteredAdvertisements?.checked_attribute_ids
          : initialData?.checked_attribute_ids,
        sortOrder: filterName,
        page: currentPage,
        limit: limit,
      },
      (response) => {
        setAdvertisementList(response.data.ads);
        setTotalAds(response.data.total);
      }
    );

  // Debouncing using setTimeout
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(timeoutId); // Clear timeout on cleanup to avoid multiple calls
    };
  }, [searchText]);

  const totalPages = Math.ceil(totalAds / limit);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const { refetch: fetchAttributeList } = useAttributeList(
    {
      category_id: parentCategory,
      sub_category_id: childCategory,
      multi_sub_category_id: checkedItems,
    },
    (res) => {
      setAttribute(res.data);
    }
  );
  const category = localStorage.getItem("category")
    ? JSON.parse(localStorage.getItem("category"))
    : "";

  const formattedParentData = [];
  const stateList = [];
  const cityList = [];
  // Push the initial blank option
  // formattedParentData.push({
  //   value: "",childCategory
  //   label: t("front_page.select_parent_category"),
  // });

  // stateList.push({ value: "", label: t("front_page.select_state") });

  if (cityStateChange != null) {
    cityList.push({
      value: "",
      label: t("front_page.select_city_all"),
      state: cityStateChange,
    });
  } else {
    cityList.push({ value: "", label: t("front_page.select_city"), state: "" });
  }

  // Check if parent_category_list exists and map over its data
  if (parent_category_list?.data) {
    parent_category_list.data.forEach((item) => {
      formattedParentData.push({
        value: item.category_id,
        label: item.category_name,
      });
    });
  }

  const childCategoryName = [];
  // const label = [];
  // if (label) {
  //   label.push({
  //     value: "",
  //     label: t("front_page.select_sub_category_all"),
  //   });
  // }
  const cityDatastate = location?.state?.cityData;
  if (stateChange || (isApplied && checkedItems.length > 0) || cityDatastate) {
    childCategoryName.push({
      value: "",
      label: t("front_page.select_sub_category"),
    });
  } else {
    childCategoryName.push({
      value: "",
      label: t("front_page.select_sub_category_all"),
    });
  }

  if (child_category_list?.data) {
    child_category_list.data.forEach((item) => {
      childCategoryName.push({
        value: item.category_id,
        label: item.category_name,
        post_count: item.post_count,
      });
    });
  }
  // Check if state name exists and map over its data
  if (state_list?.data.states) {
    state_list.data.states
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.forEach((item) => {
        stateList.push({
          value: item.state_id,
          label: item.name,
        });
      });
  }
  // Check if city name exists and map over its data
  if (city_list?.data.cities) {
    city_list.data.cities
      ?.sort((a, b) => a.city.localeCompare(b.city))
      ?.forEach((item) => {
        cityList.push({
          value: item.city_id,
          label: item.city,
          state: item.state_id,
        });
      });
  }

  //filter by date options
  const filterbyDate = [
    { value: 1, label: `${t("front_page.new_list")}` },
    { value: 2, label: `${t("front_page.old_list")}` },
    { value: 3, label: `${t("front_page.all_list")}` },
  ];

  const handleSearch = (search_text) => {
    setSearchText(search_text.target.value);
  };

  const handleCheckboxChange = (value) => {
    // setChildCategory(value);
    if (checkedItems.includes(value)) {
      setCheckedItems((prevItems) =>
        prevItems.filter((item) => item !== value)
      );
    } else {
      setCheckedItems((prev) => [...prev, value]);
    }
  };

  const handleAttributeCheckboxChange = (attributeId) => {
    if (checkedAttributes.includes(attributeId)) {
      setcheckedAttributes((prevItems) =>
        prevItems.filter((item) => item !== attributeId)
      );
    } else {
      setcheckedAttributes((prev) => [
        ...prev,
        attributeId, // Toggle the checkbox state
      ]);
    }
  };
  const handleReset = () => {
    setCurrentPage(1);
    setIsChanged(true);
    setCheckedItems([]);
    setcheckedAttributes([]);
    setFilteredAdvertisements(initialFilters);
  };

  const handleCategoryChange = (selectedOption) => {
    setChildCategory(selectedOption.value);
    refetchChildCategory();
    refetchAdvertisement();
    setClearCategory(true);
    setCheckedItems([]);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <FullScreenHeader
            getSettingData={getSettingData}
            parentCategory={parentCategory}
            setParentCategory={setParentCategory}
            childCategory={childCategory}
            setChildCategory={setChildCategory}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            formattedParentData={formattedParentData}
            childCategoryName={childCategoryName}
            stateList={stateList}
            cityList={cityList}
            refetchChildCategory={refetchChildCategory}
            refetchAdvertisement={refetchAdvertisement}
            selectedCityName={selectedCityName}
            setCityStateChange={setCityStateChange}
            cityStateChange={cityStateChange}
            handleReset={handleReset}
            setStateName={setStateName}
            setClearCategory={setClearCategory}
            setStateChange={setStateChange}
            handleCategoryChange={handleCategoryChange}
          ></FullScreenHeader>
        </Row>
        <Row className="d-flex flex-nowrap">
          <FullScreenLeftMenu
            sharedVariable={sharedVariable}
            setSharedVariable={setSharedVariable}
            childCategoryName={childCategoryName}
            checkedItems={checkedItems}
            handleCheckboxChange={handleCheckboxChange}
            handleAttributeCheckboxChange={handleAttributeCheckboxChange}
            attribute={attribute}
            checkedAttributes={checkedAttributes}
            setParentCategory={setParentCategory}
            parentCategory={parentCategory}
            setChildCategory={setChildCategory}
            setSelectedCity={setSelectedCity}
            setSelectedState={setSelectedState}
            setCheckedItems={setCheckedItems}
            handleReset={handleReset}
            handleApply={handleApply}
            childCategory={childCategory}
          />
          <ViewAdsTabs
            setSharedVariable={setSharedVariable}
            getSettingData={getSettingData}
            seoData={getSeoData?.data}
            t={t}
            customStyles={customStyles}
            searchText={searchText}
            handleSearch={handleSearch}
            setFilterByDate={setFilterByDate}
            refetchAdvertisement={refetchAdvertisement}
            filterbyDate={filterbyDate}
            advertisementdata={advertisementdata}
            isAdsLoading={isAdsLoading}
            filterName={filterName}
            stateList={stateList}
            cityList={cityList}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
            parent_category_list={parent_category_list}
            parentCategory={parentCategory}
            setCurrentPage={setCurrentPage}
            cityName={cityName}
            stateName={stateName}
            selectedCity={selectedCity}
            selectedState={selectedState}
          />
        </Row>
      </Container>
    </>
  );
}

export default PostAdsList;
