/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "assets/scss/page/_main.scss";
import { useListCategory } from "hooks";
import Masonry from "react-masonry-css";
import { useNavigate } from "react-router-dom";
import { POST_ADVERTISEMENT } from "config";
import { useSelector } from "react-redux";
import { SeoHeader } from "common";

const Main = ({ getSettingData, t }) => {
  const navigate = useNavigate();
  const cityData = useSelector((state) => state.cityState);
  const {
    data,
    isLoading,
    error,
    refetch: doGetcategories,
  } = useListCategory({
    search_text_filter: "",
  });

  const [categoriesData, setCategoriesData] = useState({});

  useEffect(() => {
    if (data) {
      const organizedCategories = {};
      data.data.category_list.forEach((category) => {
        organizedCategories[category.parent_category_name] =
          category.sub_categories.sort((a, b) => a.sequence - b.sequence);
      });
      setCategoriesData(organizedCategories);
    }
  }, [data]);
  const handleClickRedirectPostListing = (e, item, parent) => {
    e.preventDefault();
    const category = {
      category_id: item.category_id,
      category_name: item.category_name,
      parent_id: item.parent_id ? item.parent_id : item.category_id,
      parentOrChild: "",
    };
    localStorage.setItem("category", JSON.stringify(category));

    localStorage.setItem("city_data", JSON.stringify(cityData));
    const data = {
      state: cityData?.state_name ? cityData.state_name : null,
      city: cityData?.name ? cityData.name : null,
      category: parent,
      subCategory: category?.category_name,
    };
    localStorage.setItem("urlData", JSON.stringify(data));
    navigate(
      `/search${
        data.state != null
          ? "/" + data.state.toLowerCase().trim().replace(/\s+/g, "-")
          : ""
      }${
        data.city != null
          ? "/" + data.city.toLowerCase().trim().replace(/\s+/g, "-")
          : ""
      }/${category?.category_name.toLowerCase().trim().replace(/\s+/g, "-")}`,

      {
        state: { category },
      }
    );
  };

  const renderCategoryList = (items, category) => {
    if (category == "Services" || (items.length <= 22 && category != "Gigs")) {
      return (
        <ul className="list-unstyled category-list">
          {items.map((item, index) => (
            <li
              key={index}
              onClick={(e) => handleClickRedirectPostListing(e, item, category)}
            >
              <a
                href="#"
                onClick={(e) =>
                  handleClickRedirectPostListing(e, item, category)
                }
                className="hover-underline"
              >
                {item.category_name}
              </a>
            </li>
          ))}
        </ul>
      );
    }

    const midIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midIndex);
    const secondHalf = items.slice(midIndex);

    return (
      <Row>
        <Col xs={12} md={6} className="pe-md-0">
          <ul className="list-unstyled category-list">
            {firstHalf.map((item, index) => (
              <li
                key={index}
                onClick={(e) => handleClickRedirectPostListing(e, item)}
              >
                <a
                  href="#"
                  onClick={(e) => handleClickRedirectPostListing(e, item)}
                  className="hover-underline"
                >
                  {item.category_name}
                </a>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={6} className="ps-md-1">
          <ul className="list-unstyled category-list">
            {secondHalf.map((item, index) => (
              <li
                key={index}
                onClick={(e) => handleClickRedirectPostListing(e, item)}
              >
                <a
                  href="#"
                  onClick={(e) => handleClickRedirectPostListing(e, item)}
                  className="hover-underline"
                >
                  {item.category_name}
                </a>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    );
  };

  // Define the breakpoint columns for masonry
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <>
      {getSettingData != undefined && (
        <SeoHeader
          title={
            getSettingData?.data?.home_page_general_seo_title +
            " | " +
            t("front_page.url_title_common")
          }
          description={getSettingData?.data?.home_page_general_seo_description}
          seoTitle={getSettingData?.data?.home_page_general_seo_title}
          seoDescription={
            getSettingData?.data?.home_page_general_seo_description
          }
          image={getSettingData?.data?.home_page_general_favicon_logo}
          favIcon={getSettingData?.data?.home_page_general_favicon_logo}
        />
      )}

      <Container fluid className="content-area home-container">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {isLoading && <div>Loading...</div>}
          {error && <div>Error: {error.message}</div>}

          {Object.keys(categoriesData).map((categoryName) => {
            const categoryList = categoriesData[categoryName];
            return (
              <div key={categoryName}>
                <Card className="bg-transparent  border-0 text-white p-0">
                  <div
                    className="custom-heading"
                    onClick={(e) => {
                      e.preventDefault();
                      const category = {
                        category_id: "",
                        category_name: categoryName,
                        parent_id: categoryList[0].parent_id,
                        parentOrChild: "parent",
                      };
                      localStorage.setItem(
                        "category",
                        JSON.stringify(category)
                      );
                      const data = {
                        state: cityData?.state_name
                          ? cityData.state_name
                          : null,
                        city: cityData?.name ? cityData.name : null,
                        category: category?.category_name,
                        subCategory: null,
                      };
                      localStorage.setItem("urlData", JSON.stringify(data));
                      localStorage.setItem(
                        "city_data",
                        JSON.stringify(cityData)
                      );

                      navigate(
                        `/search${
                          data.state != null
                            ? "/" +
                              data.state
                                .toLowerCase()
                                .trim()
                                .replace(/\s+/g, "-")
                            : ""
                        }${
                          data.city != null
                            ? "/" +
                              data.city
                                .toLowerCase()
                                .trim()
                                .replace(/\s+/g, "-")
                            : ""
                        }/${category?.category_name
                          .toLowerCase()
                          .trim()
                          .replace(/\s+/g, "-")}`,
                        {
                          state: { category },
                        }
                      );
                    }}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        const category = {
                          category_id: "",
                          category_name: categoryName,
                          parent_id: categoryList[0].parent_id,
                          parentOrChild: "parent",
                        };
                        localStorage.setItem(
                          "category",
                          JSON.stringify(category)
                        );
                        const data = {
                          state: cityData?.state_name
                            ? cityData.state_name
                            : null,
                          city: cityData?.name ? cityData.name : null,
                          category: category?.category_name,
                          subCategory: null,
                        };
                        localStorage.setItem("urlData", JSON.stringify(data));
                        localStorage.setItem(
                          "city_data",
                          JSON.stringify(cityData)
                        );

                        navigate(
                          `/search${
                            data.state != null
                              ? "/" +
                                data.state
                                  .toLowerCase()
                                  .trim()
                                  .replace(/\s+/g, "-")
                              : ""
                          }${
                            data.city != null
                              ? "/" +
                                data.city
                                  .toLowerCase()
                                  .trim()
                                  .replace(/\s+/g, "-")
                              : ""
                          }/${category?.category_name
                            .toLowerCase()
                            .trim()
                            .replace(/\s+/g, "-")}`,
                          {
                            state: { category },
                          }
                        );
                      }}
                      className="hover-underline"
                    >
                      {categoryName}
                    </a>
                  </div>
                  <Card.Body>
                    {renderCategoryList(
                      categoriesData[categoryName],
                      categoryName
                    )}
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </Masonry>
      </Container>
    </>
  );
};

export default Main;
