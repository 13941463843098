import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loggedUser } from "store";
import { requestForToken, onMessageListener } from "./firebase";
import { client } from "libs";

const Notification = () => {
  const dispatch = useDispatch();
  const checkLoggedInUser = useSelector(loggedUser);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast.info(<ToastDisplay />);
  const ToastDisplay = () => {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (checkLoggedInUser.isUserLogin) {
      // Request for token and then set a timeout for notifications
      requestForToken(dispatch);

      // Delay the notification check after login
      const notificationTimeout = setTimeout(() => {
        if (notification?.title) {
          notify();
        }
      }, 1500); // Adjust the timeout duration as needed

      // Cleanup timeout on unmount
      return () => clearTimeout(notificationTimeout);
    } else if (notification?.title) {
      notify();
    }
  }, [notification, checkLoggedInUser]);

  onMessageListener()
    .then(() => {})
    .catch((err) => console.error(err.message));
};

export default Notification;
