import React, { useState } from "react";
import { useSelector } from "react-redux";

import "assets/scss/page/_dashboard.scss";
import { useGetAdsCounter } from "hooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ADS, LIST_ADS, COMPLETED, CANCELLED, HOME } from "config";
import { CommonHelmet } from "common";
import { Spinner } from "react-bootstrap";

const Dashboard = ({ getSettingData, t }) => {
  const [counter, setCounter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const hasRedirected = localStorage.getItem("redirected");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const { refetch } = useGetAdsCounter(
    { user_id: user?.user_id },
    (response) => {
      if (response) {
        const adsData = response?.data;
        if (adsData?.code == -1 && !hasRedirected) {
          setIsLoading(false);
          localStorage.setItem("redirected", "true");
          navigate(HOME);
          return;
        } else {
          setCounter(response?.data);
          setIsLoading(false);
        }
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );

  return (
    <>
      <CommonHelmet
        title={"front_page.dashboard_seo_title"}
        description={"front_page.dashboard_seo_title"}
      />
      {isLoading ? (
        <Spinner
          animation="border"
          className="spinner-background"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <div className="dashboard p-3">
          <h2 className="mb-4">{t("front_page.dashboard_title")}</h2>
          <div className="dashboard-container">
            <div
              className="dashboard-block"
              onClick={() => {
                if (counter.code !== -1) {
                  navigate(ADS + LIST_ADS);
                }
              }}
            >
              <h2 className="block-title">
                {t("front_page.current_ads_tilte")}
              </h2>
              <p className="counter">
                {counter?.code != -1 ? counter?.currentAdsCount : 0}
              </p>
            </div>
            <div
              className="dashboard-block"
              onClick={() => {
                if (counter?.code !== -1) {
                  navigate(ADS + COMPLETED);
                }
              }}
            >
              <h2 className="block-title">
                {t("front_page.complete_ads_tilte")}
              </h2>
              <p className="counter">
                {counter?.code != -1 ? counter?.completedAdsCount : 0}
              </p>
            </div>
            <div
              className="dashboard-block"
              onClick={() => {
                if (counter?.code !== -1) {
                  navigate(ADS + CANCELLED);
                }
              }}
            >
              <h2 className="block-title">
                {t("front_page.cancelled_ads_tilte")}
              </h2>
              <p className="counter">
                {counter?.code != -1 ? counter?.cancelledAdsCount : 0}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
