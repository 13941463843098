import React, { useState } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { BiBell, BiChat } from "react-icons/bi";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setNotifications } from "store";
import default_img from "assets/images/account_circle.svg";
import header_logo from "assets/images/header_logo.png";
import { useGetNotifications, useListUnreadMessage } from "hooks";
import { HOME } from "config";
import { CHAT } from "config";
import { NOTIFICATIONS } from "config";
import { EDIT_PROFILE } from "config";

const PrivateHeader = ({ toggleSidebar, getSettingData, t, socket }) => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [unreadCount, setUnreadCount] = useState(0);
  const notificationCount = useSelector((state) => state?.notifications?.count);

  const { refetch } = useGetNotifications((res) => {
    dispatch(setNotifications(res.data));
  });
  const { refetch: unreadMsg } = useListUnreadMessage((res) => {
    setUnreadCount(res.data.length);
  });

  socket.on("read_message", () => {
    unreadMsg();
  });

  return (
    <Navbar
      bg="theme"
      variant="dark"
      className="justify-content-between pvt-header ps-3"
    >
      <Navbar.Brand className="private-header">
        <Link to={HOME} className="text-light text-decoration-none">
          <img
            src={
              getSettingData?.data.home_page_general_home_page_logo ||
              header_logo
            }
            alt="Header logo"
            className="home-logo"
          />
        </Link>
      </Navbar.Brand>
      <Nav className="d-flex align-items-center gap-md-2 me-3">
        <Link
          to={CHAT}
          data-toggle="tooltip"
          data-placement="center"
          title={t("front_page.tool_tip_chat")}
        >
          <BiChat size={28} color="#fff" />
        </Link>
        {unreadCount > 0 && (
          <span className="notification-counts">{unreadCount}</span>
        )}
        <Link
          to={NOTIFICATIONS}
          data-toggle="tooltip"
          data-placement="center"
          className="position-relative"
          title={t("front_page.tool_tip_notification")}
        >
          <BiBell size={28} color="#fff" />
        </Link>
        {notificationCount > 0 && (
          <span className="notification-counts">{notificationCount}</span>
        )}
        <Link to={EDIT_PROFILE}>
          <Image
            src={
              user?.user?.profile_image.includes("default.jpeg")
                ? default_img
                : user?.user?.profile_image
            }
            roundedCircle
            width="40"
            height="40"
            data-toggle="tooltip"
            data-placement="center"
            title={t("front_page.tool_tip_user_profile")}
            className="me-3 me-lg-2"
          />
        </Link>
        <Nav.Link
          onClick={toggleSidebar}
          data-toggle="tooltip"
          data-placement="center"
          title={t("front_page.tool_tip_settings")}
        >
          <FontAwesomeIcon icon={faGear} className="text-light fs-4" />
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

PrivateHeader.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default PrivateHeader;
