import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Sidebar } from "../Sidebar/Sidebar";
import { RightSidebar } from "../RightSidebar/RightSidebar";
import "assets/scss/_custom.scss";

const PublicLayout = ({ getSettingData, children, t }) => {
  const [currentView, setCurrentView] = useState("desktop");
  const [isMobile, setIsMobile] = useState(false);
  const [city, setCity] = useState("");
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const location = useLocation();

  const currentPage = location.pathname;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");
    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
      if (e.matches) {
        setCurrentView("phone");
      } else {
        setCurrentView("desktop");
      }
    };

    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  // const [sidebars, setSidebars] = useState({
  //   left: false,
  //   right: false,
  // });

  // const handleToggleSidebar = (leftOpen, rightOpen) => {
  //   setSidebars({
  //     left: leftOpen,
  //     right: rightOpen,
  //   });
  // };

  const hideHeaderAndSidebarsPatterns = [
    "/about",
    "/contact",
    "/faq",
    "/page/*",
  ];

  const hideAllComponentsPatterns = [
    "/login",
    "/register",
    "/otp-verification",
    "/forgot-password",
    "/reset-password",
  ];

  const openFullscreen = ["/abcd", "/user-profile-view/*", "/search/*"];
  const { advertisement_info } = useParams();
  const isMatchingPath = (pattern, path) => {
    const regex = new RegExp(`^${pattern.replace("*", ".*")}$`);
    return regex.test(path);
  };
  const hideHeaderAndSidebars = hideHeaderAndSidebarsPatterns.some((pattern) =>
    isMatchingPath(pattern, currentPage)
  );

  const hideAllComponents = hideAllComponentsPatterns.some((pattern) =>
    isMatchingPath(pattern, currentPage)
  );

  const openInFullScreen = openFullscreen.some((pattern) =>
    isMatchingPath(pattern, currentPage)
  );

  return (
    <>
      {openInFullScreen || advertisement_info ? (
        <>
          {children}
          <Footer getSettingData={getSettingData} t={t} />
        </>
      ) : (
        <>
          <div
            className={`${currentView == "desktop" ? "super-container" : ""}`}
          >
            <div className="main-content">
              {!hideHeaderAndSidebars &&
                !hideAllComponents &&
                currentView === "desktop" && (
                  <Sidebar getSettingData={getSettingData} t={t} />
                )}
              <div className="d-flex flex-column flex-grow-1 bg-theme">
                {!hideAllComponents && (
                  <Header
                    hideHeaderAndSidebars={hideHeaderAndSidebars}
                    getSettingData={getSettingData}
                    t={t}
                    isNavbarOpen={isNavbarOpen}
                    setIsNavbarOpen={setIsNavbarOpen}
                    // onToggleSidebar={handleToggleSidebar}
                    city={city}
                    setCity={setCity}
                  />
                )}

                <div
                  className={`flex-grow-1 text-light ${
                    !hideHeaderAndSidebars
                      ? hideAllComponents
                        ? "custom-container"
                        : "main-container"
                      : currentView === "desktop"
                      ? "mt-0"
                      : "mt-lg-5 custom-container-height"
                  }`}
                >
                  {children}
                </div>
              </div>
              {!hideHeaderAndSidebars &&
                !hideAllComponents &&
                currentView === "desktop" && (
                  <RightSidebar
                    getSettingData={getSettingData}
                    t={t}
                    setCity={setCity}
                  />
                )}
            </div>
          </div>
          <Footer getSettingData={getSettingData} t={t} />
        </>
      )}
    </>
  );
};

PublicLayout.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  t: PropTypes.func,
};

export { PublicLayout };
