import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import adsImage from "assets/images/no_image.png";
import { useNavigate } from "react-router-dom";

const LazyImage = ({ alt, src, postList }) => {
  const navigate = useNavigate();
  const handleNavigation = (postList) => {
    const advertisement_id = postList?.advertisement_id;
    const advertisement_name = postList?.post_title;

    navigate(
      `/${advertisement_name
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9\s]+/g, "")
        .replace(/\s+/g, "-")
        .replace(/-$/, "")}-${advertisement_id}`
    );
  };
  return (
    <LazyLoadImage
      alt={alt}
      src={src}
      effect="blur"
      onClick={() => handleNavigation(postList)}
      // placeholderSrc={adsImage}
    />
  );
};

export default LazyImage;
