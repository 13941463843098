import { t } from "i18next";
import React from "react";
import { Helmet } from "react-helmet";
import { useGetSettingData } from "hooks";

const CommonHelmet = ({ title, description }) => {
  const { data: setting } = useGetSettingData(
    () => {},
    (error) => {
      if (error?.data?.redirect == true) {
        console.error("Error occurred: ", error.message);
      }
    }
  );
  const titles = t(title) + " | " + t("front_page.url_title_common");
  const descriptions = description || titles;
  return (
    <Helmet>
      <link rel="icon" href={setting?.data?.home_page_general_favicon_logo} />
      <title>
        {titles.includes("undefined") == false
          ? titles
          : t("front_page.url_title_common")}
      </title>
      <meta name="title" content={titles} />
      <meta name="description" content={descriptions} />
      <meta name="og:title" content={titles} />
      <meta name="og:description" content={descriptions} />
    </Helmet>
  );
};

export default CommonHelmet;
