import React from "react";

function LikeFillButton() {
  return (
    <>
      <svg
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ads-like-btn"
      >
        <g clipPath="url(#clip0_3266_950)">
          <g filter="url(#filter0_d_3266_950)">
            <path
              d="M44 11H20C15.5817 11 12 14.5817 12 19V43C12 47.4183 15.5817 51 20 51H44C48.4183 51 52 47.4183 52 43V19C52 14.5817 48.4183 11 44 11Z"
              fill="#1C4532"
            />
          </g>
          <path
            d="M32.08 40.483L30.5699 39.1292C28.8167 37.5497 27.3673 36.1872 26.2216 35.0416C25.076 33.8961 24.1646 32.8677 23.4877 31.9564C22.8107 31.0452 22.3377 30.2077 22.0686 29.444C21.7996 28.6803 21.665 27.8993 21.665 27.1008C21.665 25.4693 22.2118 24.1068 23.3054 23.0133C24.399 21.9198 25.7616 21.373 27.3933 21.373C28.2959 21.373 29.1552 21.564 29.971 21.9458C30.7868 22.3277 31.4899 22.8657 32.08 23.56C32.6702 22.8657 33.3732 22.3277 34.1891 21.9458C35.0049 21.564 35.8642 21.373 36.7668 21.373C38.3985 21.373 39.7611 21.9198 40.8547 23.0133C41.9483 24.1068 42.495 25.4693 42.495 27.1008C42.495 27.8993 42.3605 28.6803 42.0915 29.444C41.8224 30.2077 41.3494 31.0452 40.6724 31.9564C39.9954 32.8677 39.0841 33.8961 37.9385 35.0416C36.7928 36.1872 35.3434 37.5497 33.5902 39.1292L32.08 40.483ZM33.3297 30.1331C34.5795 27.9852 34.7531 30.3024 35.8293 29.2349C36.9056 28.1675 38.1207 31.8349 38.7456 31.0452C38.3289 31.7343 36.5238 30.6022 36.7668 29.986C37.0098 29.3698 36.4542 30.4673 36.4542 29.8598C36.4542 28.8184 35.2739 31.1789 34.5795 30.4846C33.8852 29.7904 35.621 29.8598 34.5795 29.8598C34.7474 29.8598 33.7637 29.8598 34.5795 29.8598C33.8852 30.3197 33.3297 29.2349 33.9546 29.8598L31.455 28.9363C30.8301 28.6101 30.2747 29.07 29.5803 28.6101C28.886 28.1501 29.1464 27.9852 28.3305 27.9852C27.289 27.9852 27.7056 26.1107 27.8566 27.1008C27.1623 27.7951 36.194 30.0038 36.194 31.0452C36.194 31.6527 33.7116 29.8685 33.9546 30.4846C34.1977 31.1008 25.6747 31.9564 25.8309 31.1095C26.4558 31.8992 33.1129 29.3257 34.1891 30.3931C35.2653 31.4606 31.455 29.2349 33.3297 30.1331Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_3266_950"
            x="0"
            y="0"
            width="68"
            height="68"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="3" />
            <feGaussianBlur stdDeviation="7" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3266_950"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3266_950"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_3266_950">
            <rect width="68" height="68" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default LikeFillButton;
