import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Nav } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import "assets/scss/section/_private_sidebar.scss";
import user_icon from "assets/images/account_circle.svg";
import path from "assets/images/Path.svg";
import add_card from "assets/images/add_card.svg";
import business_user from "assets/images/business_user.svg";
import favorite from "assets/images/favorite.svg";
import move_item from "assets/images/move_item.svg";
import receipt_long from "assets/images/receipt_long.svg";
import reviews from "assets/images/reviews.svg";
import { logoutSuccess } from "store/features/authSlice";
import { useClearDraft, useGetLastAds, useListBusinessUsers } from "hooks";
import {
  EDIT_PROFILE,
  BECOME_BUSINESS_USER,
  ADS,
  LIST_ADS,
  COMPLETED,
  CANCELLED,
  FAVORITES,
  MANAGE_CARD,
  PAYMENT_HISTORY,
  FAQ,
  EDIT_BECOME_BUSINESS_USER,
  HOME,
  CREATE_ADS,
  SELECT_CATEGORY,
} from "config";
import { t } from "i18next";

const PrivateSidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(null);
  const [isBusinessStatusPending, setIsBusinessStatusPending] = useState(null);
  const [previousAds, setPreviousAds] = useState([]);
  const user = useSelector((state) => state?.user);
  const { mutate: doClearDraft, isLoading: clearingDraft } = useClearDraft(
    (res) => {},
    (error) => {
      toast.error(error.message);
    }
  );
  const handleLogout = async () => {
    const ads_id = localStorage.getItem("adsId");
    await doClearDraft({ advertisement_id: ads_id });
    localStorage.removeItem("redirected");
    localStorage.removeItem("route");
    dispatch(logoutSuccess());
    navigate(HOME);
  };

  const { refetch: doGetList, isLoading: listingData } = useListBusinessUsers(
    (res) => {
      if (res.data) {
        const user = res.data;
        setIsBusinessStatusPending(user.status);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const { mutate: doGetLastAds, isLoading: gettingAds } = useGetLastAds(
    (res) => {
      if (Object.keys(res?.data).length > 0) {
        setPreviousAds([res?.data]);
      } else {
        setPreviousAds([]);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );

  useEffect(() => {
    if (user?.user?.user_id !== undefined) {
      doGetLastAds({ user_id: user?.user?.user_id });
    }
  }, [isOpen]);

  const handleCreatePostBtn = async () => {
    if (previousAds && previousAds.length > 0) {
      navigate(ADS + CREATE_ADS, {
        state: {
          subcategoryIds: previousAds[0]?.sub_category_id,
          parentCategory: previousAds[0]?.category_id,
          advertisementId: previousAds[0]?.advertisement_id,
          step: previousAds[0]?.step,
        },
      });
      toggleSidebar();
    } else {
      navigate(ADS + SELECT_CATEGORY);
      toggleSidebar();
    }
  };

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <div
      className={`pvt-sidebar vh-100 text-white p-3 ${
        isOpen ? "pvt-sidebar-open" : "pvt-sidebar-closed"
      }`}
    >
      <button
        className="pvt-sidebar-close-btn"
        onClick={toggleSidebar}
        aria-label="Close sidebar"
      >
        &times;
      </button>

      <Nav className="flex-column mt-4 sidebar-content">
        <Link
          to={EDIT_PROFILE}
          className="text-white text-decoration-none ms-md-3 my-2"
          onClick={toggleSidebar}
        >
          <img src={user_icon} alt="" /> {t("front_page.sidebar_my_profile")}
        </Link>
        {isBusinessStatusPending !== 1 ? (
          <>
            <Link
              to={BECOME_BUSINESS_USER}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <div className="d-flex">
                <img src={business_user} alt="" />
                <div>{t("front_page.sidebar_become_business_user")}</div>
              </div>
            </Link>
            <Accordion activeKey={activeKey} onSelect={handleToggle}>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  className={activeKey === "0" ? "custom-acc-header" : "my-2"}
                >
                  {activeKey === "0" ? <FaChevronUp /> : <FaChevronDown />}
                  <img src={path} alt="" className="me-3 ms-1" />
                  {t("front_page.sidebar_ads_managements")}
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <span
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={handleCreatePostBtn}
                    style={{ cursor: "pointer" }}
                  >
                    {t("front_page.sidebar_create_ads")}
                  </span>

                  <Link
                    to={ADS + LIST_ADS}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_ads_list")}
                  </Link>

                  <Link
                    to={ADS + COMPLETED}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_completed_ads")}
                  </Link>
                  <Link
                    to={ADS + CANCELLED}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_cancelled_ads")}
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Link
              to={ADS + FAVORITES}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={favorite} alt="" /> {t("front_page.sidebar_favorite")}
            </Link>
            <Link
              to={MANAGE_CARD}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={add_card} alt="" />{" "}
              {t("front_page.sidebar_manage_cards")}
            </Link>
            <Link
              to={PAYMENT_HISTORY}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={receipt_long} alt="" />{" "}
              {t("front_page.sidebar_payment_history")}
            </Link>

            <Link
              to={FAQ}
              onClick={toggleSidebar}
              className="text-white my-2 text-decoration-none ms-md-3"
            >
              <img src={reviews} alt="" /> {t("front_page.sidebar_help_center")}
            </Link>
          </>
        ) : (
          <>
            <Link
              to={EDIT_BECOME_BUSINESS_USER}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={business_user} alt="" />{" "}
              {t("front_page.sidebar_business_profile")}
            </Link>
            <Accordion activeKey={activeKey} onSelect={handleToggle}>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  className={activeKey === "0" ? "custom-acc-header" : "my-2"}
                >
                  {activeKey === "0" ? <FaChevronUp /> : <FaChevronDown />}
                  <img src={path} alt="" className="me-3 ms-1" />
                  {t("front_page.sidebar_ads_managements")}
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <span
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={handleCreatePostBtn}
                    style={{ cursor: "pointer" }}
                  >
                    {t("front_page.sidebar_create_ads")}
                  </span>
                  <Link
                    to={ADS + LIST_ADS}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_ads_list")}
                  </Link>

                  <Link
                    to={ADS + COMPLETED}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_completed_ads")}
                  </Link>
                  <Link
                    to={ADS + CANCELLED}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_cancelled_ads")}
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Link
              to={ADS + FAVORITES}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={favorite} alt="" /> {t("front_page.sidebar_favorite")}
            </Link>
            <Link
              to={MANAGE_CARD}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={add_card} alt="" />{" "}
              {t("front_page.sidebar_manage_cards")}
            </Link>

            <Link
              to={PAYMENT_HISTORY}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={receipt_long} alt="" />{" "}
              {t("front_page.sidebar_payment_history")}
            </Link>
            <Link
              to={FAQ}
              onClick={toggleSidebar}
              className="text-white my-2 text-decoration-none ms-md-3"
            >
              <img src={reviews} alt="" /> {t("front_page.sidebar_help_center")}
            </Link>
          </>
        )}

        <Nav.Link
          onClick={() => {
            handleLogout();
            toggleSidebar();
          }}
          className="text-white my-2"
        >
          <img src={move_item} alt="" /> {t("front_page.sidebar_logout")}
        </Nav.Link>
      </Nav>
    </div>
  );
};

PrivateSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default PrivateSidebar;
